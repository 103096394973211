// src/SignOut.tsx
import React, { useEffect } from "react"
import { useOktaAuth } from "@okta/okta-react"

const SignOut: React.FC = () => {
    const { oktaAuth } = useOktaAuth()

    useEffect(() => {
        // Call signOut as soon as the component is mounted.
        oktaAuth.signOut()
    }, [oktaAuth])

    return <div>Signing out...</div>
}

export default SignOut
