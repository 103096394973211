// src/CustomLoginCallback.tsx
import React, { useEffect } from "react"
import { useOktaAuth } from "@okta/okta-react"
import { useHistory } from "react-router-dom"

const CustomLoginCallback: React.FC = () => {
    const { oktaAuth } = useOktaAuth()
    const history = useHistory()

    useEffect(() => {
        async function handleCallback() {
            try {
                // This call attempts to process tokens from the URL if they exist.
                await oktaAuth.handleLoginRedirect()
            } catch (err) {
                console.error("Error handling login redirect", err)
            }
            // After handling the callback (or if there’s nothing to process), redirect to "/"
            history.replace("/")
        }
        handleCallback()
    }, [oktaAuth, history])

    return <div>Signing in...</div>
}

export default CustomLoginCallback
