// src/OktaCustomSignIn.tsx
import React, { useState } from "react"
import { useOktaAuth } from "@okta/okta-react"
import MfaChallenge from "./MfaChallenge"
import logo from "./assets/LogoSquare.png"
import "./OktaCustomSignIn.scss"

const OktaCustomSignIn: React.FC = () => {
    const { oktaAuth, authState } = useOktaAuth()
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [mfaTransaction, setMfaTransaction] = useState<any>(null)

    const signIn = async (e: React.FormEvent) => {
        e.preventDefault()
        setError(null)
        setLoading(true)

        try {
            const transaction = await oktaAuth.signIn({ username: email, password: password })
            if (transaction.status === "SUCCESS" && transaction.sessionToken) {
                await oktaAuth.signInWithRedirect({ sessionToken: transaction.sessionToken })
            } else if (transaction.status === "MFA_REQUIRED") {
                // Save the transaction so we can handle MFA.
                setMfaTransaction(transaction)
            } else {
                throw new Error("Could not sign in: " + transaction.status)
            }
        } catch (err: any) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }

    // If MFA is required, render the MFA challenge component.
    if (mfaTransaction) {
        return <MfaChallenge transaction={mfaTransaction} />
    }

    // (Optionally) if already authenticated, you could render something else.
    if (authState && authState.isAuthenticated) {
        return <div>You are already signed in.</div>
    }

    return (
        <div className="container">
            <form onSubmit={signIn} className="card">
                <img className="logo" src={logo} alt="Logo" />
                {/* <div className="headline">Carrier Intelligence Hub</div> */}
                <div className="text">Please sign in to continue.</div>
                {error && <p className="errorMessage">{error}</p>}
                <input type="text" placeholder="Email" className="inputField" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <input type="password" placeholder="Password" className="inputField" value={password} onChange={(e) => setPassword(e.target.value)} required autoComplete="off" />
                <button type="submit" className="button" disabled={loading}>
                    {loading ? "Signing In..." : "Sign In"}
                </button>
            </form>
        </div>
    )
}

export default OktaCustomSignIn
