// src/App.tsx
import React from "react"
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom"
import { Security, LoginCallback, useOktaAuth } from "@okta/okta-react"
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js"
import { AuthProvider } from "./AuthContext"
import OktaCustomSignIn from "./OktaCustomSignIn"
import SuperblocksEmbed from "./SuperblocksEmbed"
import SignOut from "./SignOut" // Import the new component
import CustomLoginCallback from "./CustomLoginCallback"

// Configure OktaAuth with your settings.
const oktaAuth = new OktaAuth({
    issuer: "https://sso.apps.carrier.com",
    clientId: "0oaqtswnhtEzI7w6D4x7",
    redirectUri: window.location.origin + "/callback",
    pkce: true,
})

// A wrapper component that provides the Security context and sets up routes.
const SecurityWrapper: React.FC = () => {
    const history = useHistory()

    // Function that navigates back after authentication.
    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin))
    }

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Switch>
                <Route path="/callback" component={CustomLoginCallback} />
                <Route path="/signout" component={SignOut} />
                <Route path="*">
                    <AuthenticatedContent />
                </Route>
            </Switch>
        </Security>
    )
}

// Component that selects what to show based on Okta's auth state.
const AuthenticatedContent: React.FC = () => {
    const { authState } = useOktaAuth()

    return authState && authState.isAuthenticated ? <SuperblocksEmbed /> : <OktaCustomSignIn />
}

const App: React.FC = () => {
    return (
        <AuthProvider>
            <BrowserRouter>
                <SecurityWrapper />
            </BrowserRouter>
        </AuthProvider>
    )
}

export default App
