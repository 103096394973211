// src/App.tsx
import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useOktaAuth } from "@okta/okta-react"
import { SuperblocksEmbed } from "@superblocksteam/embed-react"
import config from "./config"
import "./App.css"

const App: React.FC = () => {
    const location = useLocation()
    const { authState, oktaAuth } = useOktaAuth()
    const [superblocksToken, setSuperblocksToken] = useState<string | undefined>(undefined)
    const path = `${location.pathname}${location.search}`

    // Okta login function
    const login = async () => {
        await oktaAuth.signInWithRedirect()
    }

    // Okta logout function
    const logout = async () => {
        await oktaAuth.signOut()
    }

    // Retrieve the Superblocks token from your lambda endpoint
    const getSuperblocksToken = async (accessToken: any, idToken: any) => {
        try {
            const res = await fetch(config.resourceServer.tokenUrl, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken.accessToken}`,
                    "Content-Type": "application/json",
                    "X-ID-Token": idToken.idToken,
                },
            })
            if (!res.ok) {
                throw new Error(`Superblocks auth error: ${res.status}`)
            }
            const data = await res.json()
            setSuperblocksToken(data?.access_token)
        } catch (err: any) {
            console.error("Error retrieving Superblocks token", err)
        }
    }

    // When authenticated, retrieve the Superblocks token
    useEffect(() => {
        if (authState?.isAuthenticated && authState.accessToken && authState.idToken) {
            getSuperblocksToken(authState.accessToken, authState.idToken)
        }
    }, [authState])

    // Handler to sync browser URL with Superblocks App paths
    const extractCustomRoute = (url: string): string => {
        const regex = /https:\/\/app\.superblocks\.com\/embed\/applications\/[a-f0-9\-]+(\/.*)/
        const match = url.match(regex)
        return match && match[1] ? match[1] : "/"
    }

    const handleNavigation = (event: any) => {
        let route = extractCustomRoute(event.url)
        console.log(`User navigated to: ${route}`)
        // Update browser URL using pushState or your router's mechanism
        window.history.pushState({ path: route }, "", route)
    }

    // Handle Superblocks auth errors (e.g., session expiration)
    const handleAuthError = (err: any) => {
        console.error("Superblocks auth error", err)
        // You could force a refresh of the token here if needed.
        if (authState?.isAuthenticated) {
            getSuperblocksToken(authState.accessToken, authState.idToken)
        }
    }

    // Handle custom events emitted from Superblocks
    const handleEvents = (event: string) => {
        switch (event) {
            case "SignOut":
                logout()
                break
            default:
                console.log(`Unknown event ${event}`)
        }
    }

    return (
        <div className="App" style={{ height: "100vh", overflow: "hidden" }}>
            {authState?.isAuthenticated ? (
                <SuperblocksEmbed src={`https://app.superblocks.com/embed/applications/${config.superblocks.appId}${path}`} onNavigation={handleNavigation} onAuthError={handleAuthError} onEvent={handleEvents} token={superblocksToken} />
            ) : (
                <div>
                    <h2>Please login</h2>
                    <button onClick={login}>Login with Okta</button>
                </div>
            )}
        </div>
    )
}

export default App
