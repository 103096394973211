// src/AuthContext.tsx
import React, { createContext, useState, useContext } from "react"

interface AuthContextType {
    token: string | null
    setToken: (token: string) => void
    signOut: () => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [token, setTokenState] = useState<string | null>(null)

    const setToken = (newToken: string) => {
        setTokenState(newToken)
        // Optionally, persist token (e.g., localStorage) here.
    }

    const signOut = () => {
        setTokenState(null)
        // Remove any persisted token here if needed.
    }

    return <AuthContext.Provider value={{ token, setToken, signOut }}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider")
    }
    return context
}
