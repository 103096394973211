// src/MfaChallenge.tsx
import React, { useState } from "react"
import { useOktaAuth } from "@okta/okta-react"
import logo from "./assets/LogoSquare.png"
import "./MfaChallenge.scss"

interface MfaChallengeProps {
    transaction: any // The MFA transaction from oktaAuth.signIn()
}

const MfaChallenge: React.FC<MfaChallengeProps> = ({ transaction }) => {
    const { oktaAuth } = useOktaAuth()
    // Initially, no flow is selected.
    const [selectedFlow, setSelectedFlow] = useState<"none" | "push" | "totp">("none")
    const [selectedFactor, setSelectedFactor] = useState<any>(null)
    const [passCode, setPassCode] = useState<string>("")
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    // Polling for push approval
    const pollForPushApproval = async (factor: any) => {
        const pollInterval = 2000 // poll every 2 seconds
        return new Promise<void>((resolve, reject) => {
            const poll = setInterval(async () => {
                try {
                    // Use the passed factor here.
                    const pollResponse = await factor.verify({ stateToken: transaction.stateToken })
                    console.log("Polling MFA push: ", pollResponse)
                    if (pollResponse.status === "SUCCESS" && pollResponse.sessionToken) {
                        clearInterval(poll)
                        await oktaAuth.signInWithRedirect({ sessionToken: pollResponse.sessionToken })
                        resolve()
                    } else if (pollResponse.status !== "MFA_CHALLENGE") {
                        clearInterval(poll)
                        reject(new Error("Unexpected MFA status: " + pollResponse.status))
                    }
                    // Otherwise, keep polling...
                } catch (err: any) {
                    clearInterval(poll)
                    reject(err)
                }
            }, pollInterval)
        })
    }

    // Handler for initial factor selection.
    const handleFlowSelect = (factor: any, flow: "push" | "totp") => {
        setSelectedFactor(factor)
        setSelectedFlow(flow)
        if (flow === "push") {
            // Immediately start the push process using the chosen factor.
            pollForPushApproval(factor).catch((err) => setError(err.message))
        }
    }

    // Handler for verifying TOTP code.
    const handleTotpVerify = async () => {
        setError(null)
        setLoading(true)
        try {
            const verifyOptions = {
                stateToken: transaction.stateToken,
                passCode: passCode,
            }
            const verifyResponse = await selectedFactor.verify(verifyOptions)
            if (verifyResponse.status === "SUCCESS" && verifyResponse.sessionToken) {
                await oktaAuth.signInWithRedirect({ sessionToken: verifyResponse.sessionToken })
            } else {
                throw new Error("Unexpected status: " + verifyResponse.status)
            }
        } catch (err: any) {
            setError(err.message)
        } finally {
            setLoading(false)
        }
    }

    // Filter factors to only include one push factor and one totp factor.
    const pushFactor = transaction.factors.find((factor: any) => factor.factorType === "push")
    const totpFactor = transaction.factors.find((factor: any) => factor.factorType === "token:software:totp")

    return (
        <div className="container">
            <div className="card">
                <img className="logo" src={logo} alt="Logo" />
                <div className="text">Multi-Factor Authentication Required.</div>

                {/* Show factor selection only when no flow is selected */}
                {selectedFlow === "none" && (
                    <div className="button-group">
                        {pushFactor && (
                            <button className="button" onClick={() => handleFlowSelect(pushFactor, "push")}>
                                Get Push Notification
                            </button>
                        )}
                        {totpFactor && (
                            <button className="button" onClick={() => handleFlowSelect(totpFactor, "totp")}>
                                Use Okta App Code
                            </button>
                        )}
                    </div>
                )}

                {/* For push flow, display a message while polling */}
                {selectedFlow === "push" && (
                    <div className="message">
                        Push Notification Sent.
                        <br />
                        Please approve on your device...
                    </div>
                )}

                {/* For TOTP flow, show input and verify button */}
                {selectedFlow === "totp" && (
                    <div className="totp-section">
                        <input type="text" className="inputField" value={passCode} onChange={(e) => setPassCode(e.target.value)} placeholder="Enter verification code" />
                        <button className="button" onClick={handleTotpVerify} disabled={loading}>
                            {loading ? "Verifying..." : "Verify Code"}
                        </button>
                    </div>
                )}

                {error && <p className="errorMessage">{error}</p>}
            </div>
        </div>
    )
}

export default MfaChallenge
